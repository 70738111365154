import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import Link from '../../Link/index'
import useEmblaCarousel from 'embla-carousel-react';
import { PrevButton, NextButton, EmBlaCarouselArrowButtons } from "../../panels/Button/EmblaCarouselArrowButtons";
import Autoplay from 'embla-carousel-autoplay'

const ExploreContainer = styled.div`
`
const ExploreHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1025px) {
    margin: 0px;
  }
`
const ExploreSubHeading = styled.h4`
  text-transform: uppercase;
  font-size: 13px;
  line-height: 60px;
  letter-spacing: 0.27px;
  text-align: center;
  color: #282828;
`
const ExploreHeading = styled.h2`
  font-size: 32px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -1.17px;
  text-align: center;
  color: #000000;
`
const PanelContainer = styled.div`
  display: block;
  position: relative;
  max-width: ${(props) => props.small ? '1000px' : '1400px'};;
  margin: 0 auto;
  @media (max-width: 1025px) {
    max-width: 100vw;
    flex-direction: column;
  }
}
`
const ExploreText = styled.div`
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -1.17px;
  text-align: center;
  color: ${(props) => (props.textColour ? props.textColour : '#ffffff;')};
  font-family: ${(props) => (props.font ? props.font : 'Objectivity;')};
  z-index: 999;
  transition: 0.3s opacity;
  ${(props) => (props.displayOn ? 'opacity: 1;' : 'opacity: 0;')};
  @media (max-width: 1025px) {
    font-size: 32px;
  }
`
const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all 0.2s;
  transform: scale(1);
  &:hover {
    transform: scale(1.05);
  }
`
const ImageContainerStatic = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: default;
`
const ExploreImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  @media (max-width: 768px) {
  }
`
const ExplorePanelExt = styled.div`
  user-select: none;
  width: 363px;
  height: 474px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  @media (max-width: 1025px) {
    width: calc(100vw - 64px);
    height: 474px;
  }
  &:hover > .text {
    opacity: 1 !important;
  }
`
const DetailTitle = styled.div`
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.61;
  letter-spacing: -0.67px;
  color: #000000;
  margin: 0 20px;
  width: 373px;
  @media (max-width: 1025px) {
    width: calc(100vw - 70px);
  }
`
const DetailSub = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.31px;
  color: #6d7278;
  margin: 0 20px;
  width: 373px;
  @media (max-width: 1025px) {
    width: calc(100vw - 70px);
  }
  margin-bottom: 12px;
`
const ExploreAll = styled.div`
  text-transform: uppercase;
  font-family: Objectivity;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.15px;
  text-align: center;
  color: rgb(var(--terracota));
  margin-top: 48px;

  & p {
    display: inline !important;
  }
`
const PanelCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
`
const StaticHeaderImage = styled.img`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    height: calc(100vh - 110px);
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;' : '')}
  }
  object-fit: cover;
`

const Embla = styled.div`
  overflow: hidden;
`

const EmblaContainer = styled.div`
  display: flex;
  gap: 5%;
  padding: 0 5%;
  .embla__slide > div {
   width: 100%;
  }
  @media (max-width: 1300px) {
    gap: 0;
    padding: 0;
  }
`

const ExplorePanel = ({ image, copy, title, sub, displayOn, to, hoverText, textColour, HoverTextOn, font }) => {
  let shopText = copy
  return (
    <PanelCenter>
      <ExplorePanelExt>
        {!to ? (
          <ImageContainerStatic>
            {image?.file?.isStatic ? (
              <StaticHeaderImage src={image?.file?.url} />
            ) : (
              <ExploreImage
                alt={image?.alternativeText || image?.name}
                objectFit="cover"
                image={withArtDirection(getImage(image?.file?.childImageSharp?.gatsbyImageData), [
                  {
                    media: '(max-width: 1024px)',
                    image: getImage(image?.file?.childImageSharp?.gatsbyImageDataMobile),
                  },
                ])}
                layout="constrained"
              />
            )}
          </ImageContainerStatic>
        ) : (
          <ImageContainer>
            {image?.file?.isStatic ? (
              <StaticHeaderImage src={image?.file?.url} />
            ) : (
              <ExploreImage
                alt={image?.alternativeText || image?.name}
                objectFit="cover"
                image={withArtDirection(getImage(image.file?.childImageSharp?.gatsbyImageData), [
                  {
                    media: '(max-width: 1024px)',
                    image: getImage(image.file?.childImageSharp?.gatsbyImageDataMobile),
                  },
                ])}
                layout="constrained"
              />
            )}
          </ImageContainer>
        )}
        <ExploreText font={font} displayOn={displayOn} className="text" textColour={textColour}>
          {title !== false && HoverTextOn !== false && !font && 'Shop'}{title !== false && HoverTextOn !== false && font && 'shop'} {HoverTextOn !== false && shopText}
        </ExploreText>
      </ExplorePanelExt>
      {
        title !== false && (
          <DetailTitle>{copy}</DetailTitle>
        )
      }
      {
        sub && (
          <DetailSub> {sub} </DetailSub>
        )
      }
    </PanelCenter>
  )
}
const UpsellProductTiles = ({ data }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 1,
    align: 'center',
    skipSnaps: false,
    dragFree: false,
    loop: true,
    containScroll: 'keepSnaps',
  }, [
    Autoplay({ playOnInit: true, delay: 3000 })
  ]);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = EmBlaCarouselArrowButtons(emblaApi)

  return (
    <ExploreContainer>
      {
        <ExploreHeaderContainer>
          {
            data.SubHeadingText && <ExploreSubHeading> {data.SubHeadingText} </ExploreSubHeading>
          }
          {
            data.HeadingText && <ExploreHeading> {data.HeadingText} </ExploreHeading>
          }
        </ExploreHeaderContainer>
      }
      <PanelContainer descOn={data?.ProductTiles[0]?.TileSubHeading} small={data?.ProductTiles.length < 3 ? 'true' : undefined}>
        <Embla ref={emblaRef}>
          <EmblaContainer>
            {(() => {
              let productTiles = data.ProductTiles;
              if (productTiles.length <= 3) {
                productTiles = [...productTiles, ...productTiles];
              }
              return productTiles.map(
                (
                  {
                    TileImage,
                    TileOverlayText,
                    TileSubHeading,
                    TileTextDisplayOn,
                    to,
                    TileOverlayTextOn,
                    HoverText,
                    HoverTextOn
                  },
                  key
                ) => (
                  <div key={key}>
                    {to ? (
                      <Link to={to} key={key}>
                        <ExplorePanel
                          font={data.OverlayFont}
                          copy={TileOverlayText}
                          title={TileTextDisplayOn && <>{TileTextDisplayOn}</>}
                          sub={TileSubHeading}
                          image={TileImage}
                          displayOn={TileOverlayTextOn}
                          to={to}
                          hoverText={HoverText}
                          textColour={data.TextColor}
                          HoverTextOn={HoverTextOn}
                        />
                      </Link>
                    ) : (
                      <ExplorePanel
                        font={data.OverlayFont}
                        copy={TileOverlayText}
                        title={TileTextDisplayOn && <>{TileTextDisplayOn}</>}
                        sub={TileSubHeading}
                        image={TileImage}
                        displayOn={TileOverlayTextOn}
                        to={to}
                        hoverText={HoverText}
                        textColour={data.TextColor}
                        HoverTextOn={HoverTextOn}
                      />
                    )}
                  </div>
                )
              );
            })()}
          </EmblaContainer>
        </Embla>
        <PrevButton style={{ top: '50%', left: '-25px' }} onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
        <NextButton style={{ top: '50%', right: '-25px' }} onClick={onNextButtonClick} disabled={nextBtnDisabled} />
      </PanelContainer>
      {data.BrowseTheRange && (
        <ExploreAll>
          <a href="/carry-all" style={{ color: 'rgb(var(--terracota))', fontWeight: 500 }}>
            {' '}
            {'browse the range'}{' '}
          </a>
        </ExploreAll>
      )}
    </ExploreContainer>
  )
}

export default UpsellProductTiles
